@font-face {
  font-family: "Biotif-Regular";
  src: url("./fonts/biotif/biotif.eot");
  src: url("./fonts/biotif/biotif.eot?#iefix") format("embedded-opentype"),
    url("./fonts/biotif/biotif.woff2") format("woff2"),
    url("./fonts/biotif/biotif.woff") format("woff"),
    url("./fonts/biotif/biotif.ttf") format("truetype"),
    url("./fonts/biotif/biotif.svg#youworkforthem") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Biotif-Medium";
  src: url("./fonts/biotif/biotif-medium.eot");
  src: url("./fonts/biotif/biotif-medium.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/biotif/biotif-medium.woff2") format("woff2"),
    url("./fonts/biotif/biotif-medium.woff") format("woff"),
    url("./fonts/biotif/biotif-medium.ttf") format("truetype"),
    url("./fonts/biotif/biotif-medium.svg#youworkforthem") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Biotif-Bold";
  src: url("./fonts/biotif/biotif-bold.eot");
  src: url("./fonts/biotif/biotif-bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/biotif/biotif-bold.woff2") format("woff2"),
    url("./fonts/biotif/biotif-bold.woff") format("woff"),
    url("./fonts/biotif/biotif-bold.ttf") format("truetype"),
    url("./fonts/biotif/biotif-bold.svg#youworkforthem") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "IvarDisplay-Regular";
  src: url("./fonts/ivar/IvarDisplay-Regular.woff2") format("woff2"),
    url("./fonts/ivar/IvarDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html body {
  height: 100vh;
  padding: 0;
  margin: 0;
  background: #f6f4fa;
  font-family: "Biotif-Regular", sans-serif;
}
#root {
  height: 100%;
}
.App {
  background-color: #f6f4fa;
  height: 100%;
}

.nav-logo-link {
  display: flex;
}

ul.emptyDecimal {
  list-style-type: none;
}
ul.emptyDecimal li {
  margin-bottom: 12px;
}
ul.emptyDecimal li ul {
  margin: 12px 0;
}
ul.emptyDecimal span {
  display: inline-block;
  padding-right: 30px;
  font-weight: bold;
}

.step-block {
  background-color: #f6f4fa;
  border-radius: 16px;
  display: flex;
  width: 100%;
  max-width: 380px;
  padding: 28px 32px 32px;
  margin: 10px;
  flex-direction: column;
  align-items: flex-start;
}
.step-block p {
  color: #431a98;
  font-size: 20px;
  line-height: 26px;
  font-family: "Biotif-Regular";
}
.step-block .step-number {
  color: #fff;
  background-color: #7e58fa;
  font-family: "Biotif-Bold";
  text-transform: uppercase;
  font-size: 14px;
  padding: 3px 12px;
  border-radius: 50px;
  margin-bottom: 1rem;
}

.feature-block {
  border-radius: 16px;
  margin: 10px;
  width: 380px;
  height: 210px;
  background-color: #f6f4fa;
  padding: 36px 32px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.feature-block p {
  font-size: 22px;
  line-height: 28px;
  font-family: "Biotif-Medium";
  color: #270e67;
  padding-right: 20px;
}

.nav-link {
  color: #6023de;
  text-decoration: none;
  margin-right: 24px;
}

html body .submit-button {
  background-color: #b7abff;
  font-size: 1rem;
  text-transform: none;
  color: #431a98;
  font-weight: 600;
}
html body .submit-button:hover {
  background-color: #431a98;
  color: #fff;
}

html body .submit-button svg path {
  transition: stroke 250ms ease-in-out;
}
html body .submit-button:hover svg path {
  stroke: #fff;
}

@media (max-width: 600px) {
  .nav-link {
    display: none;
  }
  .step-block {
    max-width: 90%;
  }
}
